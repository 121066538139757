<template>
  <v-row>
    <v-col
      cols="12"
      order="1"
      class="align-self-left"
    >
      <b>
        {{ $t('dashboard.contrateHotels') }}
      </b><br />
      {{ $t('dashboard.contrateHotels2') }}
    </v-col>
    <v-col
      v-for="(contrate, index) in contratesHotels"
      :key="index"
      cols="6"
      order="1"
      class="align-self-center"
    >
      <VencimientoHotels
        :contrate="contrate"
      />
    </v-col>
  </v-row>
</template>

<script>

import VencimientoHotels from './contrates/VencimientoHotels.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    VencimientoHotels,
  },
  data() {
    return {
      permisos: localStorage.getItem('permisos_auth'),
      isLoadingContratesHotels: true,
      contratesHotels: [],
      user: {},
    }
  },
  created() {
    const operators = []
    operators.push('Operador 1')
    operators.push('Operador 2')

    this.contratesHotels.push({
      identificador: 'AFR-45712',
      user: {
        nivel: 0,
      },
      operador: 'ATG',
      operators,
      source: ['hotetec'],
      cant_sin_tarifarios: 6,
      cant_sin_tarifas: 4,
      cant_vencen_3: 2,
      cant_vencen_6: 6,
    })

    this.contratesHotels.push({
      identificador: 'TRW-879465',
      user: {
        nivel: 1,
      },
      operador: 'WST',
      operators: ['Operador 1'],
      source: ['dingus'],
      cant_sin_tarifarios: 4,
      cant_sin_tarifas: 2,
      cant_vencen_3: 2,
      cant_vencen_6: 1,
    })
  },
}
</script>
